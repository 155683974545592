<template>
  <v-card class="mt-3">
    <v-switch
      v-model="exempt"
      label="Billing Exempt"
      class="pa-3"
      color="success"
      hide-details
      @change="updateExemptStatus"
    ></v-switch>
  </v-card>
</template>

<script>
import Api from "@/api/billing";
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      exempt: false,
    };
  },
  mounted() {
    this.getExemptStatus();
  },
  methods: {
    getExemptStatus() {
      Api.getExemptStatus(this.id).then((resp) => {
        this.exempt = resp.data.billing_exempt;
      });
    },
    updateExemptStatus() {
      Api.updateExemptStatus(this.id, this.exempt)
        .then((resp) => {
          if (!resp.success) {
            this.notifyError();
          } else {
            this.notifySuccess();
          }
        })
        .catch((_error) => {
          this.notifyError();
        });
    },
    notifyError() {
      this.$notify({
        group: "notifs",
        title: "Request Failed",
        text: "Failed to update billing exemption.",
        type: "error",
      });
    },
    notifySuccess() {
      this.$notify({
        group: "notifs",
        title: "Success",
        text: "Successfully updated billing exemption.",
        type: "success",
      });
    },
  },
};
</script>
