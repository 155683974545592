import OAuthPopup from "./popup";
import whitelabelConfig from "@/whitelabel.config";

export default {
  /**
   * Creates a Google OAuth popup.
   *
   * Valid options:
   *   - optionalScopes: Additional scopes to request
   *   - prompt: Use 'consent' to enable account selection. Otherwise
   *             the popup closes immediately if cache is present.
   *
   * @param {string} title Title of the popup
   * @param {string} redirectUri URL to redirect after popup closes
   * @param {object} opts Options
   * @returns {OAuthPopup}
   */
  googlePopup(title, redirectUri, opts = {}) {
    let optionalScopes = opts.optionalScopes || [];

    let scope = [
      "https://www.googleapis.com/auth/userinfo.email",
      "https://www.googleapis.com/auth/userinfo.profile",
      "openid",
    ]
      .concat(optionalScopes)
      .join(" ");

    let params = {
      access_type: "offline",
      client_id: this.getWhiteLabelConfig().googleClientId,
      response_type: "code",
      redirect_uri: redirectUri,
      scope: scope,
    };

    if (opts.prompt != null) {
      params.prompt = opts.prompt;
    }

    let baseUrl = "https://accounts.google.com/o/oauth2/v2/auth";
    let url = this.encodeUrl(baseUrl, params);

    let options = { width: 452, height: 633 };
    return new OAuthPopup(url, title, options);
  },

  /**
   * Creates a Microsoft OAuth popup.
   *
   * Valid options:
   *   - optionalScopes: Additional scopes to request
   *   - prompt: Use 'select_account' to enable account selection.
   *             Otherwise the popup closes immediately if cache is
   *             present.
   *
   * @param {string} title Title of the popup
   * @param {string} redirectUri URL to redirect after popup closes
   * @param {object} opts Options
   * @returns {OAuthPopup}
   */
  microsoftPopup(title, redirectUri, opts = {}) {
    let optionalScopes = opts.optionalScopes || [];

    let scope = ["offline_access", "user.read", "email", "openid", "profile"]
      .concat(optionalScopes)
      .join(" ");

    let params = {
      client_id: this.getWhiteLabelConfig().microsoftClientId,
      response_type: "code",
      scope: scope,
      redirect_uri: redirectUri,
    };

    if (opts.prompt != null) {
      params.prompt = opts.prompt;
    }

    let baseUrl =
      "https://login.microsoftonline.com/common/oauth2/v2.0/authorize";
    let url = this.encodeUrl(baseUrl, params);

    let options = { width: 500, height: 560 };
    return new OAuthPopup(url, "Register an Account", options);
  },

  encodeUrl(baseUrl, params) {
    let query = this.encodeQuery(params);
    return `${baseUrl}?${query}`;
  },

  encodeQuery(params) {
    var esc = encodeURIComponent;
    return Object.keys(params)
      .map((k) => esc(k) + "=" + esc(params[k]))
      .join("&");
  },

  getWhiteLabelConfig() {
    return whitelabelConfig[process.env.VUE_APP_BRAND];
  },
};
