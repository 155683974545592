<template>
  <v-dialog
    v-model="open"
    width="390"
    style="overflow: auto; -webkit-overflow-scrolling: touch"
    @keydown.esc="close"
  >
    <v-card class="pa-2">
      <v-card-title class="card-title"> Import </v-card-title>
      <v-card-text>
        Are you sure you want to import "{{ fileName }}" on behalf of the user
        {{ name }}
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn ref="cancelButton" color="primary" flat @click="close">
          Cancel
        </v-btn>
        <v-btn ref="submitButton" color="navy" dark @click="submit">
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-progress-linear v-if="isLoading" class="ma-0" indeterminate />
  </v-dialog>
</template>

<script>
export default {
  name: "ConfirmImport",
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    fileName: {
      type: String,
      required: true,
      default: "",
    },
    name: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      open: this.value,
      errors: [],
    };
  },
  watch: {
    value: {
      handler() {
        this.open = this.value;
      },
    },
    open: {
      handler() {
        if (!this.open) {
          this.close();
        }
      },
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    submit() {
      this.$emit("submit");
    },
  },
};
</script>
