<template>
  <div class="color-picker">
    <template v-for="c in swatches">
      <v-avatar :key="c" class="ma-1" :color="c" @click="select(c)">
        <v-icon v-if="selected(c)" color="white"> check </v-icon>
      </v-avatar>
    </template>
  </div>
</template>

<script>
export default {
  name: "ColorPicker",
  props: {
    color: {
      type: String,
      default: "#F44336",
    },
  },
  data() {
    return {
      tempColor: this.color,
      swatches: [
        "#F44336",
        "#E91E63",
        "#9C27B0",
        "#673AB7",
        "#3F51B5",
        "#2196F3",
        "#03A9F4",
        "#00BCD4",
        "#009688",
        "#4CAF50",
        "#8BC34A",
        "#CDDC39",
        "#FFEB3B",
        "#FFC107",
        "#FF9800",
        "#FF5722",
        "#795548",
        "#9E9E9E",
        "#607D8B",
      ],
    };
  },
  methods: {
    selected(color) {
      return this.tempColor === color;
    },
    select(color) {
      this.tempColor = color;
      this.$emit("select", color);
    },
  },
};
</script>
