<template>
  <div class="wrapper">
    <top-navbar />
    <dashboard-content />
  </div>
</template>

<script>
import DashboardContent from "./DashboardContent.vue";
import TopNavbar from "./TopNavbar.vue";

export default {
  components: {
    DashboardContent,
    TopNavbar,
  },
};
</script>
