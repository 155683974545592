<template>
  <v-container grid-list-lg fluid>
    <v-text-field
      v-model="search"
      prepend-inner-icon="search"
      label="Search"
      solo
      single-line
      clearable
      @keyup.enter="requestFetchAccounts"
    />
    <v-card>
      <v-card-title class="card-title">
        <v-icon class="mr-2"> domain </v-icon>
        Teams
        <v-spacer />
        <v-btn
          color="primary"
          class="ma-0 elevation-0"
          dark
          @click="newAccountDialog = true"
        >
          <v-icon left> add </v-icon>
          Create Team
        </v-btn>
      </v-card-title>
      <v-data-table
        :fetching-data="isLoading"
        :headers="headers"
        :items="accounts"
        :loading="isLoading"
        :pagination.sync="pagination"
        :rows-per-page-items="rowsPerPageOptions"
        :total-items="pagination.totalItems"
      >
        <v-progress-linear
          slot="progress"
          color="primary"
          indeterminate
        ></v-progress-linear>
        <template slot="items" slot-scope="props">
          <router-link :to="{ path: '/accounts/' + props.item.id }" tag="tr">
            <td>{{ props.item.internal_id }}</td>
            <td>{{ props.item.id }}</td>
            <td>{{ props.item.name }}</td>
            <td>{{ props.item.total_users }}</td>
          </router-link>
        </template>
      </v-data-table>
    </v-card>
    <NewAccountDialog
      ref="newAccountDialog"
      v-model="newAccountDialog"
      @create="addAccount($event)"
      @close="hidenewAccountDialog"
      @update-accounts-table="requestFetchAccounts"
    />
  </v-container>
</template>

<script>
import AccountApi from "@/api/accounts";
import NewAccountDialog from "@/components/accounts/NewAccountDialog";

export default {
  name: "AccountsView",
  components: {
    NewAccountDialog,
  },
  data() {
    return {
      accounts: [],
      breadcrumbs: [
        {
          text: "Teams",
          disabled: false,
          href: "/accounts",
        },
      ],
      headers: [
        { text: "ID", value: "id", align: "left", sortable: true }, // this is the numeric primary key
        { text: "Hash ID", value: "hash_id", align: "left", sortable: false }, // this is the hashed version of the primary key
        { text: "Name", value: "name", align: "left", sortable: true },
        { text: "# Users", value: "total_users", sortable: true },
      ],
      isLoading: false,
      newAccountDialog: false,
      rowsPerPageOptions: [20, 50, 100],
      pagination: {
        descending: true,
        page: 1,
        rowsPerPage: 20,
        sortBy: "total_users",
      },
      search: "",
    };
  },
  computed: {
    isSearching() {
      return this.search != null && this.search != "";
    },
  },
  watch: {
    pagination: {
      handler() {
        this.requestFetchAccounts();
      },
    },
    search: {
      handler() {
        // Fetch accounts when clear button is clicked
        if (this.search != null) {
          return;
        }
        this.requestFetchAccounts();
      },
    },
  },
  methods: {
    addAccount(account) {
      this.accounts.push(account);
    },
    hidenewAccountDialog() {
      this.newAccountDialog = false;
    },
    offset() {
      const { page, rowsPerPage } = this.pagination;
      return (page - 1) * rowsPerPage;
    },
    requestFetchAccounts() {
      const { descending, sortBy, rowsPerPage } = this.pagination;
      const offset = this.offset();

      var params = {
        offset: offset,
        limit: rowsPerPage,
        sort_by: sortBy,
        dir: descending ? "desc" : "asc",
        admin: true,
      };

      if (this.isSearching) {
        params.q = this.search;
      }

      AccountApi.fetchAccounts(params)
        .then((resp) => {
          this.accounts = resp.data;
          this.$set(this.pagination, "totalItems", resp.total);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
