<template>
  <v-card class="elevation-0 transparent my-2">
    <v-card-title class="card-title">
      <v-icon class="mr-3"> file_upload </v-icon>
      Import Contacts
      <v-spacer />
    </v-card-title>
    <v-layout row>
      <FileImportCard
        :id="id"
        :source="outlook"
        :name="name"
        accepts=".csv"
        type="exchange"
      />
      <FileImportCard
        :id="id"
        :source="linkedIn"
        :name="name"
        accepts=".csv, .zip"
        type="linkedin"
      />
      <FileImportCard
        :id="id"
        :source="apple"
        :name="name"
        accepts=".vcf"
        type="vcard"
      />
    </v-layout>
    <div v-for="(account, index) in accounts" :key="index">
      <OAuthImportCard
        :id="id"
        :account="account"
        :icon="importType(account.provider)"
      />
    </div>
  </v-card>
</template>

<script>
import Api from "@/api/import";
import FileImportCard from "@/components/users/FileImportCard";
import OAuthImportCard from "@/components/users/OAuthImportCard";

export default {
  components: {
    FileImportCard,
    OAuthImportCard,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
      default: "",
    },
  },
  data() {
    return {
      accounts: [],
    };
  },
  computed: {
    outlook() {
      return require("@/assets/outlook-logo.png");
    },
    google() {
      return require("@/assets/google.png");
    },
    linkedIn() {
      return require("@/assets/linkedin-logo-transparent.png");
    },
    office() {
      return require("@/assets/office-logo-transparent.png");
    },
    apple() {
      return require("@/assets/apple-logo-transparent.png");
    },
  },
  mounted() {
    this.getAccounts();
  },
  methods: {
    importType(provider) {
      switch (provider) {
        case "google":
          return this.google;
        case "office":
          return this.office;
        default:
          return "";
      }
    },
    getAccounts() {
      Api.getAccounts(this.id).then((resp) => {
        this.accounts = resp.data.oauth_credentials;
      });
    },
  },
};
</script>

<style lang="scss">
.bigger_font {
  font-size: 15px;
}
</style>
