<template>
  <div class="login">
    <img src="@/assets/computer.jpg" class="login-bg" />
    <v-layout row fill-height align-center justify-center wrap>
      <v-flex xs12 sm6 md4 lg3 xl3>
        <v-card class="elevation-15" color="#222" tile>
          <img width="100%" class="pa-5" src="@/assets/login.png" />
        </v-card>
        <v-card color="#fcfcfa" class="pa-4 elevation-15" tile>
          <v-card-text>
            <ForgotPasswordForm
              v-if="toggleForgotPassword"
              @log-in="toggleForgotPassword = false"
              @forgot-password="requestForgotPassword($event)"
            />
            <LoginForm
              v-else
              @forgot-password="toggleForgotPassword = true"
              @log-in-email="loginEmail($event)"
              @log-in-google="loginGoogle"
              @log-in-microsoft="loginMicrosoft"
            />
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import OAuth from "@/oauth";

import ForgotPasswordForm from "@/components/login/ForgotPasswordForm";
import LoginForm from "@/components/login/LoginForm";

export default {
  name: "LoginView",
  components: {
    ForgotPasswordForm,
    LoginForm,
  },
  data() {
    return {
      toggleForgotPassword: false,
    };
  },
  computed: {
    ...mapGetters({
      profile: "getProfile",
    }),
    redirectUri() {
      return `${this.$whiteLabelConfig.webUrl}/login`;
    },
  },
  watch: {
    profile: {
      handler() {
        let redirectTo = this.$route.query.from;
        if (redirectTo == undefined) {
          this.$router.push("/dashboard");
        } else {
          this.$router.push(redirectTo);
        }
      },
    },
  },
  methods: {
    ...mapActions(["fetchProfile", "login", "oauth", "requestPasswordReset"]),
    loginEmail({ email, password }) {
      this.login({ email: email, password: password })
        .then(() => {
          this.fetchProfile();
        })
        .catch((error) => {
          this.msg = error;
          this.$notify({
            group: "notifs",
            title: "Login Failed",
            text: error,
            type: "error",
          });
        });
    },
    loginGoogle() {
      let popup = OAuth.googlePopup("Login", this.redirectUri);

      popup
        .open(this.redirectUri, false)
        .then((resp) => {
          let params = {
            code: resp.code,
            provider: "google",
            redirectUri: this.redirectUri,
            type: "login",
          };

          this.oauth(params)
            .then((_) => {
              this.fetchProfile();
            })
            .catch((error) => {
              this.notifyError(error);
            });
        })
        .catch((error) => {
          if (error.message === "CLOSED") {
            return;
          }
          this.notifyError();
        });
    },
    loginMicrosoft() {
      let popup = OAuth.microsoftPopup("Login", this.redirectUri);

      popup
        .open(this.redirectUri, false)
        .then((resp) => {
          let params = {
            code: resp.code,
            provider: "microsoft",
            redirectUri: this.redirectUri,
            type: "login",
          };

          this.oauth(params)
            .then((_) => {
              this.fetchProfile();
            })
            .catch((_) => {
              this.notifyError();
            });
        })
        .catch((error) => {
          if (error.message === "CLOSED") {
            return;
          }
          this.notifyError();
        });
    },
    notifyError() {
      this.$notify({
        group: "notifs",
        title: "Authentication Failed",
        text: "Something went wrong on our end.",
        type: "error",
      });
    },
    requestForgotPassword(email) {
      this.requestPasswordReset({ email: email })
        .then(() => {
          this.$notify({
            group: "notifs",
            text: "Password reset has been requested. Please check your email.",
            title: "Success",
            type: "success",
          });
        })
        .catch((error) => {
          this.msg = error;
          this.$notify({
            group: "notifs",
            text: "Password could not be reset at this time.",
            title: "Password Reset Failed",
            type: "error",
          });
        });
    },
  },
};
</script>

<style lang="scss">
.card-title {
  text-transform: uppercase;
  letter-spacing: 7px !important;
}

.login-bg {
  bottom: 0;
  left: 0;
  height: 100%;
  object-fit: cover;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 0;
}

.logo-card {
  background: rgba(0, 0, 0, 0.5);
}
</style>
