import axios from "axios";
import Utils from "@/api/utils";

export default {
  async fetchTotals() {
    let url = Utils.accountApiUrl(`/v1/admin/totals`);
    let resp = await axios.get(url, { headers: Utils.authHeaders() });
    return resp.data;
  },
  async fetchNewUsers() {
    let url = Utils.accountApiUrl(`/v1/admin/new_users`);
    let resp = await axios.get(url, { headers: Utils.authHeaders() });
    return resp.data;
  },
  async fetchTeams() {
    let url = Utils.accountApiUrl(`/v1/admin/teams`);
    let resp = await axios.get(url, { headers: Utils.authHeaders() });
    return resp.data;
  },
  async fetchTotalConnections() {
    let url = Utils.contactApiUrl(`/v1/reports`);
    let resp = await axios.get(url, { headers: Utils.authHeaders() });
    return resp.data;
  },
};
