import axios from "axios";
import Vue from "vue";
import router from "../router";
import Utils from "@/api/utils";

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      Vue.cookie.delete("admin-token", { domain: Utils.cookieDomain });
      window.location = Utils.loginUrl();
    } else if (error.response.status === 403) {
      router.push("/forbidden");
    }
    return Promise.reject(error);
  },
);

export default {
  auth({ provider, code, type, redirectUri }) {
    let url = Utils.accountApiUrl(`/v1/oauth/${provider}`);
    return axios
      .post(url, { type: type, code: code, redirect_uri: redirectUri })
      .then((resp) => resp.data);
  },

  login(email, pass) {
    let url = Utils.accountApiUrl("/v1/oauth/email");
    return axios
      .post(url, { type: "login", email: email, password: pass })
      .then((resp) => resp.data);
  },

  fetchProfile() {
    let url = Utils.accountApiUrl("/v1/profile");
    return axios
      .get(url, { headers: Utils.authHeaders() })
      .then((resp) => resp.data);
  },
};
