<template>
  <v-toolbar class="navy elevation-1" clipped-left app>
    <v-toolbar-side-icon dark @click.stop="toggleDrawer" />
    <img width="44" :src="logoURL" />
    <v-spacer />
    <v-toolbar-items>
      <v-btn flat dark :to="profilePath">
        <v-icon left> account_circle </v-icon>
        {{ email }}
      </v-btn>
      <v-btn flat dark @click="requestLogout">
        <v-icon>exit_to_app</v-icon>
      </v-btn>
    </v-toolbar-items>
  </v-toolbar>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "AppHeader",
  computed: {
    ...mapGetters({
      profile: "getProfile",
    }),
    email() {
      if (this.profile == null) {
        return "Not set.";
      }
      return this.profile.email || "";
    },
    logoURL() {
      return this.$whiteLabelConfig.logo;
    },
    profilePath() {
      if (this.profile == null) {
        return "";
      }
      return `/users/${this.profile.id}`;
    },
  },
  created() {
    this.fetchProfile();
  },
  methods: {
    ...mapActions(["fetchProfile", "logout"]),
    requestLogout() {
      this.logout().then(() => {
        this.$router.push("/login");
      });
    },
    toggleDrawer() {
      this.$emit("toggleDrawer");
    },
  },
};
</script>
