import Vue from "vue";
import Router from "vue-router";

import AccountsView from "@/views/AccountsView";
import AccountDetailView from "@/views/AccountDetailView";
import DashboardLayout from "@/components/DashboardLayout.vue";
import DashboardView from "@/views/DashboardView";
import ForbiddenView from "@/views/ForbiddenView";
import LoginView from "@/views/LoginView";
import MOTDView from "@/views/MOTDView";
import UsersView from "@/views/UsersView";
import UserDetailView from "@/views/UserDetailView";

import whitelabelConfig from "@/whitelabel.config";

Vue.use(Router);

const hasToken = () => {
  return !!Vue.cookie.get("admin-token");
};

const isPrivileged = () => {
  const profile = JSON.parse(localStorage.getItem("admin-profile"));

  if (profile == null) {
    return false;
  }

  return ["super_admin", "admin", "agent"].includes(profile.role);
};

const isSuperAdmin = () => {
  const profile = JSON.parse(localStorage.getItem("admin-profile"));

  if (profile == null) {
    return false;
  }

  return ["super_admin"].includes(profile.role);
};

// eslint-disable-next-line
const ifNotAuthenticated = (to, from, next) => {
  if (!hasToken()) {
    next();
    return;
  }
  next("/");
};

const ifAuthenticated = (to, from, next) => {
  if (hasToken()) {
    if (isPrivileged()) {
      next();
      return;
    } else {
      next("/forbidden");
      return;
    }
  }

  window.location = whitelabelConfig[process.env.VUE_APP_BRAND].loginUrl;
};

const ifAuthenticatedSuperAdmin = (to, from, next) => {
  if (hasToken()) {
    if (isSuperAdmin()) {
      next();
    } else {
      next("/forbidden");
    }
  }
};

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/login",
      name: "Login",
      component: LoginView,
    },
    {
      path: "/forbidden",
      name: "Forbidden",
      component: ForbiddenView,
    },
    {
      path: "/",
      component: DashboardLayout,
      beforeEnter: ifAuthenticated,
      redirect: "/login",
      children: [
        {
          path: "dashboard",
          name: "Dashboard",
          component: DashboardView,
        },
        {
          path: "accounts",
          name: "Accounts",
          component: AccountsView,
        },
        {
          path: "accounts/:id",
          component: AccountDetailView,
          name: "AccountDetail",
          props: true,
        },
        {
          path: "users",
          name: "Users",
          component: UsersView,
        },
        {
          path: "users/:id",
          component: UserDetailView,
          name: "UserDetail",
          props: true,
        },
        {
          path: "agents",
          name: "Agents",
          component: UsersView,
          beforeEnter: ifAuthenticatedSuperAdmin,
          props: {
            role: "agent",
          },
        },
        {
          path: "admins",
          name: "Admins",
          component: UsersView,
          beforeEnter: ifAuthenticatedSuperAdmin,
          props: {
            role: "admin",
          },
        },
        {
          path: "super-admins",
          name: "SuperAdmins",
          component: UsersView,
          beforeEnter: ifAuthenticatedSuperAdmin,
          props: {
            role: "super_admin",
          },
        },
        {
          path: "motd",
          name: "MOTD",
          component: MOTDView,
          beforeEnter: ifAuthenticatedSuperAdmin,
        },
      ],
    },
  ],
});
