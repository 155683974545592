<template>
  <v-container grid-list-lg fluid>
    <v-layout row wrap>
      <v-flex>
        <StatCard
          v-model="connectionsTotal"
          :loading="isLoading"
          color="warning"
          icon="account_circle"
          title="Total Connections"
        />
      </v-flex>
    </v-layout>
    <v-layout row wrap>
      <v-flex xs6>
        <StatCard
          v-model="userTotal"
          :loading="isLoading"
          class="mb-3"
          color="info"
          icon="account_circle"
          title="Total Users"
        />
        <v-card>
          <v-card-title class="info" dark>
            <span class="white--text card-title">10 Newest Users</span>
          </v-card-title>
          <v-card-text class="text-xs-left">
            <v-data-table
              :headers="usersHeaders"
              :items="newUsers"
              class="elevation-0"
              hide-actions
            >
              <template v-slot:items="props">
                <router-link :to="{ path: '/users/' + props.item.id }" tag="tr">
                  <td>{{ props.item.internal_id }}</td>
                  <td>{{ props.item.id }}</td>
                  <td>{{ props.item.name }}</td>
                  <td>{{ datetimeString(props.item.time) }}</td>
                </router-link>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-flex>
      <v-flex xs6>
        <StatCard
          v-model="teamTotal"
          :loading="isLoading"
          class="mb-3"
          color="success"
          icon="people"
          title="Total Teams"
        />
        <v-card class="mb-3">
          <v-card-title class="success" dark>
            <span class="white--text card-title">5 Newest Teams</span>
          </v-card-title>
          <v-card-text class="text-xs-left">
            <v-data-table
              :headers="teamsHeaders"
              :items="newTeams"
              class="elevation-0"
              hide-actions
            >
              <template v-slot:items="props">
                <router-link
                  :to="{ path: '/accounts/' + props.item.id }"
                  tag="tr"
                >
                  <td>{{ props.item.internal_id }}</td>
                  <td>{{ props.item.id }}</td>
                  <td>{{ props.item.name }}</td>
                  <td>{{ datetimeString(props.item.time) }}</td>
                  <td class="text-xs-right">
                    {{ $n(props.item.users) }}
                  </td>
                </router-link>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
        <v-card>
          <v-card-title class="success" dark>
            <span class="white--text card-title">Top 5 Teams</span>
          </v-card-title>
          <v-card-text class="text-xs-left">
            <v-data-table
              :headers="topTeamsHeaders"
              :items="topTeams"
              class="elevation-0"
              hide-actions
            >
              <template v-slot:items="props">
                <router-link
                  :to="{ path: '/accounts/' + props.item.id }"
                  tag="tr"
                >
                  <td>{{ props.item.internal_id }}</td>
                  <td>{{ props.item.id }}</td>
                  <td>{{ props.item.name }}</td>
                  <td class="text-xs-right">
                    {{ $n(props.item.users) }}
                  </td>
                </router-link>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import DashboardApi from "@/api/dashboard";
import { mapActions } from "vuex";
import StatCard from "@/components/StatCard";

const { DateTime } = require("luxon");

export default {
  name: "DashboardView",
  components: {
    StatCard,
  },
  data() {
    return {
      connectionsTotal: 0,
      isLoading: false,
      newTeams: [],
      newUsers: [],
      teamsHeaders: [
        { text: "ID", value: "id", sortable: false },
        { text: "Hash ID", value: "hash_id", sortable: false },
        { text: "Name", value: "name", sortable: false },
        { text: "Time", value: "time", sortable: false },
        { text: "Users", value: "users", sortable: false, align: "right" },
      ],
      teamTotal: 0,
      topTeams: [],
      topTeamsHeaders: [
        { text: "ID", value: "id", sortable: false },
        { text: "Hash ID", value: "hash_id", sortable: false },
        { text: "Name", value: "name", sortable: false },
        { text: "Users", value: "users", sortable: false, align: "right" },
      ],
      usersHeaders: [
        { text: "ID", value: "id", sortable: false },
        { text: "Hash ID", value: "hash_id", sortable: false },
        { text: "Name", value: "name", sortable: false },
        { text: "Time", value: "time", sortable: false },
      ],
      userTotal: 0,
    };
  },
  async mounted() {
    this.isLoading = true;

    try {
      await Promise.all([
        this.fetchTotals(),
        this.fetchUsers(),
        this.fetchTeams(),
      ]);
    } catch (_error) {
      // Nothing
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    ...mapActions(["fetchProfile"]),
    datetimeString(datetime) {
      if (datetime == null) {
        return "Not set.";
      }
      return DateTime.fromISO(datetime, { zone: "utc" })
        .toLocal()
        .toLocaleString(DateTime.DATETIME_MED);
    },
    async fetchTeams() {
      let resp = await DashboardApi.fetchTeams();
      this.newTeams = resp.data.new_teams;
      this.topTeams = resp.data.top_teams;
    },
    async fetchTotals() {
      let results = await Promise.all([
        DashboardApi.fetchTotals(),
        DashboardApi.fetchTotalConnections(),
      ]);
      this.userTotal = results[0].data.users;
      this.teamTotal = results[0].data.teams;
      this.connectionsTotal = results[1].data.total.connections;
    },
    async fetchUsers() {
      let resp = await DashboardApi.fetchNewUsers();
      this.newUsers = resp.data;
    },
  },
};
</script>
