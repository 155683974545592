<template>
  <v-footer class="darkNavy px-3 elevation-1" height="50px" app dark>
    v{{ version }}
    <v-spacer></v-spacer>
    <div>
      &copy; {{ new Date().getFullYear() }} {{ $whiteLabelConfig.copyright }}
    </div>
    &nbsp;-&nbsp;
    <a :href="termsURL" target="_blank" class="white--text">Terms of Service</a>
    &nbsp;-&nbsp;
    <a :href="privacyURL" target="_blank" class="white--text">Privacy Policy</a>
  </v-footer>
</template>

<script>
import { version } from "../../package.json";

export default {
  name: "AppFooter",
  computed: {
    version() {
      return version;
    },
    copyright() {
      return "6DOS";
    },
    termsURL() {
      return "https://6dos.co/user-agreement/";
    },
    privacyURL() {
      return "https://6dos.co/privacy-policy/";
    },
  },
};
</script>
