<template>
  <v-card>
    <v-card-text>
      <v-form ref="form" v-model="valid">
        <v-text-field
          v-model="password"
          :append-icon="showPassword ? 'visibility_off' : 'visibility'"
          :rules="[rules.required, rules.min, rules.max]"
          :type="showPassword ? 'text' : 'password'"
          name="input-10-1"
          label="New Password"
          hint="At least 8 characters"
          counter
          @click:append="showPassword = !showPassword"
        />
        <v-btn class="primary ml-0" flat @click="updatePassword">
          Update
        </v-btn>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      password: "",
      valid: false,
      showPassword: true,
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
        max: (v) => v.length <= 100 || "Max 100 characters",
      },
    };
  },
  methods: {
    clear() {
      this.password = "";
      this.$refs.form.reset();
    },
    updatePassword() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.$emit("requestUpdatePassword", { password: this.password });
    },
  },
};
</script>
