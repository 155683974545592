<template>
  <v-card>
    <v-card-title class="card-title">
      {{ title }}
      <v-spacer />
      <v-btn class="ma-0" icon small flat @click="cancel">
        <v-icon>close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <span>
        Are you sure you want to delete? This action cannot be undone.
      </span>
    </v-card-text>
    <v-card-actions class="pa-3">
      <v-spacer />
      <v-btn class="elevation-0" color="primary" outline dark @click="cancel">
        Cancel
      </v-btn>
      <v-btn class="elevation-0" color="rubber" dark @click="requestDelete">
        Delete
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "Delete",
    },
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    requestDelete() {
      this.$emit("requestDelete");
    },
  },
};
</script>
