<template>
  <v-card>
    <v-card-title class="card-title">
      <v-icon class="mr-2"> people </v-icon>
      User Information
      <v-spacer />
      <v-btn color="primary" class="ma-0" small flat dark @click="toggleEdit">
        <v-icon left> edit </v-icon>
        Edit
      </v-btn>
    </v-card-title>
    <v-divider />
    <v-list two-line dense>
      <v-list-tile class="pt-2 pb-2">
        <v-list-tile-avatar>
          <avatar v-if="user.name" :username="user.name" :size="40"></avatar>
          <avatar v-else username=" "></avatar>
        </v-list-tile-avatar>
        <v-list-tile-content>
          <v-list-tile-title class="title">
            {{ user.name }}
          </v-list-tile-title>
          <v-list-tile-sub-title>
            {{ user.internal_id }} / {{ user.id }}
          </v-list-tile-sub-title>
        </v-list-tile-content>
      </v-list-tile>
      <v-list-tile>
        <v-list-tile-avatar>
          <v-icon>account_circle</v-icon>
        </v-list-tile-avatar>
        <v-list-tile-content>
          {{ roleName || "None specified." }}
          <v-list-tile-sub-title> Role </v-list-tile-sub-title>
        </v-list-tile-content>
      </v-list-tile>
      <v-list-tile>
        <v-list-tile-avatar>
          <v-icon>mail</v-icon>
        </v-list-tile-avatar>
        <v-list-tile-content>
          {{ user.email }}
          <v-list-tile-sub-title> E-mail </v-list-tile-sub-title>
        </v-list-tile-content>
      </v-list-tile>
      <v-list-tile>
        <v-list-tile-avatar>
          <v-icon>place</v-icon>
        </v-list-tile-avatar>
        <v-list-tile-content>
          {{ user.address || "None specified." }}
          <v-list-tile-sub-title> Address </v-list-tile-sub-title>
        </v-list-tile-content>
      </v-list-tile>
      <v-list-tile>
        <v-list-tile-avatar>
          <v-icon>phone</v-icon>
        </v-list-tile-avatar>
        <v-list-tile-content>
          {{ user.phone_number || "None specified." }}
          <v-list-tile-sub-title> Phone No. </v-list-tile-sub-title>
        </v-list-tile-content>
      </v-list-tile>
      <v-list-tile>
        <v-list-tile-avatar>
          <v-icon>calendar_today</v-icon>
        </v-list-tile-avatar>
        <v-list-tile-content>
          {{ datetimeString(user.joined_at) }}
          <v-list-tile-sub-title> Join Date </v-list-tile-sub-title>
        </v-list-tile-content>
      </v-list-tile>
      <v-list-tile>
        <v-list-tile-avatar>
          <v-icon>account_circle</v-icon>
        </v-list-tile-avatar>
        <v-list-tile-content class="status">
          {{ user.verified }}
          <v-list-tile-sub-title> Verified </v-list-tile-sub-title>
        </v-list-tile-content>
      </v-list-tile>
      <v-list-tile>
        <v-list-tile-avatar>
          <v-icon>account_circle</v-icon>
        </v-list-tile-avatar>
        <v-list-tile-content class="status">
          {{ user.active }}
          <v-list-tile-sub-title> Active </v-list-tile-sub-title>
        </v-list-tile-content>
      </v-list-tile>
    </v-list>
  </v-card>
</template>

<script>
const { DateTime } = require("luxon");

import Avatar from "vue-avatar";
import Roles from "@/roles";

export default {
  components: { Avatar },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  computed: {
    roleName() {
      return Roles.name(this.user.role);
    },
  },
  methods: {
    datetimeString(datetime) {
      if (datetime == null) {
        return "Not set.";
      }
      return DateTime.fromISO(datetime, { zone: "utc" })
        .toLocal()
        .toLocaleString(DateTime.DATETIME_MED);
    },
    toggleEdit() {
      this.$emit("toggleEdit");
    },
  },
};
</script>

<style lang="scss">
.status {
  text-transform: capitalize;
}
</style>
