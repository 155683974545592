import * as types from "./mutation-types";
import Vue from "vue";

import whitelabelConfig from "@/whitelabel.config";

function getWhiteLabelConfig() {
  return whitelabelConfig[process.env.VUE_APP_BRAND];
}

export default {
  [types.LOGIN_REQUEST](state) {
    state.fetchingData = true;
    state.error = null;
  },

  [types.LOGIN_SUCCESS](state, { email, token }) {
    state.fetchingData = false;
    state.error = null;

    localStorage.setItem("admin-email", email);
    Vue.cookie.set("admin-token", token, {
      expires: 1,
      domain: getWhiteLabelConfig().domain,
    });

    state.email = email;
  },

  [types.LOGIN_FAILURE](state, { error }) {
    state.fetchingData = false;
    state.error = error;
  },

  [types.LOGOUT](state) {
    Vue.cookie.delete("admin-token", {
      domain: getWhiteLabelConfig().domain,
    });

    localStorage.removeItem("admin-profile");
    localStorage.clear();

    state.email = null;
    state.profile = null;
    state.selectedAccount = null;
  },

  [types.FETCH_DASHBOARD_REQUEST](state) {
    state.fetchingData = true;
    state.error = null;
  },

  [types.FETCH_DASHBOARD_SUCCESS](state) {
    state.fetchingData = false;
    state.error = null;
  },

  [types.FETCH_DASHBOARD_FAILURE](state, { error }) {
    state.fetchingData = false;
    state.error = error;
  },

  [types.UPDATE_PASSWORD_REQUEST](state) {
    state.fetchingData = true;
    state.error = null;
  },

  [types.UPDATE_PASSWORD_SUCCESS](state) {
    state.fetchingData = false;
    state.error = null;
  },

  [types.UPDATE_PASSWORD_FAILURE](state, { error }) {
    state.fetchingData = false;
    state.error = error;
  },

  [types.FETCH_PROFILE_REQUEST](_state) {
    // Nothing yet
  },

  [types.FETCH_PROFILE_SUCCESS](state, { profile }) {
    localStorage.setItem("admin-profile", JSON.stringify(profile));
    state.profile = profile;
  },

  [types.FETCH_PROFILE_FAILURE](_state, { _error }) {
    // Nothing yet
  },

  [types.LOGOUT](state) {
    state.profile = null;
    state.role = null;
  },
};
