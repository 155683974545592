const whitelabelConfig = {
  local: {
    accountApiUrl: "http://localhost:4000",
    contactApiUrl: "http://localhost:4010",
    contactImportApiUrl: "http://localhost:4060",
    billingApiUrl: "http://localhost:4090",
    domain: "localhost",
    loginUrl: "http://localhost:8081/login",
    copyright: "Development Server",
    logo: require("@/assets/img/6dos-logo-circle.png"),
    googleClientId:
      "21087456568-qelaj5f5grg98sci2ukfj63vej4aam8i.apps.googleusercontent.com",
    microsoftClientId: "0467a776-2947-469b-88ed-9388b18154f7",
    webUrl: "http://localhost:8081",
  },
  dev: {
    accountApiUrl: "https://accounts-api.6dos.dev",
    contactApiUrl: "https://contacts-api.6dos.dev",
    contactImportApiUrl: "https://contact-importer-api.6dos.dev",
    billingApiUrl: "https://billing-api.6dos.dev",
    domain: "6dos.dev",
    ganalyticsId: "G-R4XE1JN9LN",
    loginUrl: "https://admin.6dos.dev/login",
    copyright: "6DOS",
    googleClientId:
      "666644998405-a58mfq53t8mjqcnt66r9ho6oufv4h5pn.apps.googleusercontent.com",
    microsoftClientId: "2daf105f-632e-44b9-b3ef-5a908ae58858",
    logo: require("@/assets/img/6dos-logo-circle.png"),
    webUrl: "https://admin.6dos.dev",
  },
  prod: {
    accountApiUrl: "https://accounts-api.6dos.co",
    contactApiUrl: "https://contacts-api.6dos.co",
    contactImportApiUrl: "https://contact-importer-api.6dos.co",
    billingApiUrl: "https://billing-api.6dos.co",
    domain: "6dos.co",
    ganalyticsId: "G-VBPTNEJVQW",
    loginUrl: "https://admin.6dos.co/login",
    copyright: "6DOS",
    googleClientId:
      "666644998405-a58mfq53t8mjqcnt66r9ho6oufv4h5pn.apps.googleusercontent.com",
    microsoftClientId: "40d62509-5525-4560-8005-345ec6f9cdde",
    logo: require("@/assets/img/6dos-logo-circle.png"),
    webUrl: "https://admin.6dos.co",
  },
};

export default whitelabelConfig;
