<template>
  <div class="content">
    <v-text-field
      v-model="search"
      prepend-inner-icon="search"
      label="Search"
      solo
      single-line
      clearable
      @keyup.enter="requestFetchUsers"
    ></v-text-field>
    <v-card>
      <v-card-title class="card-title">
        <v-icon class="mr-2"> people </v-icon>
        {{ title }}
        <v-spacer />
        <v-btn color="primary" class="ma-0 elevation-0" flat dark @click="open">
          <v-icon left> add </v-icon>
          Create
        </v-btn>
      </v-card-title>
      <v-data-table
        :fetching-data="isLoading"
        :headers="headers"
        :items="users"
        :loading="isLoading"
        :pagination.sync="pagination"
        :rows-per-page-items="rowsPerPageOptions"
        :total-items="pagination.totalItems"
      >
        <v-progress-linear slot="progress" color="primary" indeterminate />
        <template slot="items" slot-scope="props">
          <router-link :to="{ path: '/users/' + props.item.id }" tag="tr">
            <td>{{ props.item.internal_id }}</td>
            <td>{{ props.item.id }}</td>
            <td>{{ props.item.email }}</td>
            <td>{{ props.item.name }}</td>
            <td>
              <v-icon
                :class="boolColor(props.item.active)"
                small
                v-text="boolIcon(props.item.active)"
              />
            </td>
            <td>
              <v-icon
                :class="boolColor(props.item.verified)"
                small
                v-text="boolIcon(props.item.verified)"
              />
            </td>
          </router-link>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UserApi from "@/api/users";

const changeCase = require("change-case");

export default {
  name: "UsersTable",
  props: {
    role: {
      type: String,
      default: "customer",
    },
    title: {
      type: String,
      default: "Users",
    },
  },
  data() {
    return {
      headers: [
        { text: "ID", align: "left", value: "id", sortable: false },
        { text: "Hash ID", align: "left", value: "hash_id", sortable: false },
        { text: "E-mail", align: "left", value: "email", sortable: false },
        { text: "Name", align: "left", value: "name", sortable: false },
        { text: "Active", align: "left", value: "active", sortable: false },
        { text: "Verified", align: "left", value: "verified", sortable: false },
      ],
      isLoading: false,
      pagination: {
        descending: true,
        page: 1,
        rowsPerPage: 100,
        sortBy: "name",
      },
      rowsPerPageOptions: [100, 150, 200],
      search: "",
      users: [],
    };
  },
  computed: {
    ...mapGetters({
      profile: "getProfile",
      userRole: "getRole",
    }),
    isSearching() {
      return this.search != null && this.search != "";
    },
  },
  watch: {
    pagination: {
      handler() {
        this.requestFetchUsers();
      },
    },
    search: {
      handler() {
        // Fetch accounts when clear button is clicked
        if (this.search != null) {
          return;
        }
        this.requestFetchUsers();
      },
    },
  },
  methods: {
    open() {
      this.$emit("openForm");
    },
    titleCase(str) {
      return changeCase.titleCase(str);
    },
    boolIcon(val) {
      return val ? "check" : "close";
    },
    boolColor(val) {
      return val ? "success--text" : "error--text";
    },
    canAccess(user) {
      switch (this.userRole) {
        case "super_admin":
          return true;
        case "agent":
          if (user.id === this.profile.id) {
            return true;
          } else if (user.role === "customer") {
            return true;
          } else {
            return false;
          }
      }
    },
    offset() {
      const { page, rowsPerPage } = this.pagination;
      return (page - 1) * rowsPerPage;
    },
    requestFetchUsers() {
      const { descending, sortBy, rowsPerPage } = this.pagination;
      const offset = this.offset();

      var params = {
        offset: offset,
        limit: rowsPerPage,
        sort_by: sortBy,
        dir: descending ? "desc" : "asc",
        role: this.role,
        admin: true,
      };

      if (this.isSearching) {
        params.q = this.search;
      }

      UserApi.fetchUsers(params)
        .then((resp) => {
          this.users = resp.data;
          this.$set(this.pagination, "totalItems", resp.total);
        })
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>

<style lang="scss">
.status {
  text-transform: capitalize;
}
</style>
