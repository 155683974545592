<template>
  <v-flex xs12>
    <v-card>
      <v-list two-line dense>
        <v-list-tile>
          <img :src="source" class="mr-4" />
          <v-list-tile-content class="bigger_font">
            {{ $n(total || 0) }}
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
    </v-card>
  </v-flex>
</template>

<script>
export default {
  name: "TotalCard",
  props: {
    source: {
      type: String,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
  },
};
</script>
