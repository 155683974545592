import axios from "axios";

import Utils from "@/api/utils";

export default {
  getAccounts(userHashId) {
    let url = Utils.contactImportApiUrl(`/v1/user/${userHashId}`);
    return axios
      .get(url, { headers: Utils.authHeaders() })
      .then((resp) => resp.data);
  },

  submitDataImport(file, importType, userHashId) {
    let url = Utils.contactImportApiUrl(`/v1/import/${importType}`);

    let formData = new FormData();
    formData.append("file", file);
    formData.append("user_id", userHashId);
    formData.append("admin", true);

    var headers = {};
    headers["Content-Type"] = "mutlipart/form-data";
    headers = Object.assign(headers, Utils.authHeaders());

    return axios
      .post(url, formData, { headers: headers })
      .then((resp) => resp.data)
      .catch((error) => error.response.data);
  },

  submitOAuthImport(email, importType, userHashId) {
    let url = Utils.contactImportApiUrl(`/v1/import/${importType}`);

    let formData = new FormData();
    formData.append("email", email);
    formData.append("user_id", userHashId);
    formData.append("admin", true);

    var headers = {};
    headers["Content-Type"] = "mutlipart/form-data";
    headers = Object.assign(headers, Utils.authHeaders());

    return axios
      .post(url, formData, { headers: headers })
      .then((resp) => resp.data)
      .catch((error) => error.response.data);
  },
};
