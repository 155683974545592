<template>
  <v-container grid-list-lg fluid>
    <UsersTable
      ref="usersTable"
      :fetching-data="isLoading"
      :role="role"
      :title="$route.name"
      @openForm="showDialog"
    />
    <NewUserDialog
      ref="newUserDialog"
      v-model="dialog"
      :role="role"
      @save="save"
      @close="close"
      @update-users-table="updateUsersTable"
    />
  </v-container>
</template>

<script>
import NewUserDialog from "../components/users/NewUserDialog";
import UsersTable from "../components/users/UsersTable";

export default {
  name: "UsersView",
  components: {
    NewUserDialog,
    UsersTable,
  },
  props: {
    role: {
      type: String,
      default: "customer",
    },
  },
  data() {
    return {
      dialog: false,
      isLoading: false,
      search: "",
      userFormErrors: {},
    };
  },
  methods: {
    close() {
      this.$refs.newUserDialog.reset();
      this.dialog = false;
    },
    save(user) {
      this.$notify({
        group: "notifs",
        title: "User Created",
        text: `${user.name} has been added.`,
        type: "success",
      });
      this.close();
    },
    showDialog() {
      this.dialog = true;
    },
    updateUsersTable() {
      this.$refs.usersTable.requestFetchUsers();
    },
  },
};
</script>
