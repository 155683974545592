<template>
  <v-flex xs12>
    <v-card>
      <v-list two-line dense>
        <v-list-tile>
          <img :src="source" class="mr-4" />
          <v-spacer />
          <v-btn color="elevation-0 primary" small @click="requestImport">
            Import
            <input
              ref="file"
              :accept="accepts"
              type="file"
              style="display: none"
              @change="handleFileUpload"
            />
          </v-btn>
        </v-list-tile>
      </v-list>
    </v-card>
    <ConfirmImport
      v-model="importDialog"
      :value="importDialog"
      :file-name="file.name"
      :name="name"
      @close="closeImportDialog"
      @submit="submitImportDialog"
    />
  </v-flex>
</template>

<script>
import API from "@/api/import";
import ConfirmImport from "@/components/users/ConfirmImport";

export default {
  components: { ConfirmImport },
  props: {
    source: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    accepts: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      importDialog: false,
      file: "",
    };
  },
  methods: {
    clearFileInput() {
      this.file = "";
      const input = this.$refs.file;
      input.type = "text";
      input.type = "file";
    },
    closeImportDialog() {
      this.clearFileInput();
      this.importDialog = false;
    },
    submitImportDialog() {
      this.submitFile();
    },
    handleFileUpload() {
      this.file = this.$refs.file.files[0];
      if (this.file == null) {
        return;
      }

      this.isLoading = true;
      this.importDialog = true;
    },
    submitFile() {
      let importTypes = {
        vcard: "icloud",
        linkedin: "linkedin",
        exchange: "exchange",
        pdfs: "pdfs",
      };

      this.importDialog = false;
      this.notifySuccess();

      API.submitDataImport(this.file, importTypes[this.type], this.id)
        .then((resp) => {
          if (!resp.success) {
            this.notifyError(resp.error.message);
          } else {
            this.notifySuccess();
          }
        })
        .catch((_error) => {
          this.notifyError();
        })
        .finally(() => {
          this.clearFileInput();
          this.isLoading = false;
        });
    },
    requestImport() {
      switch (this.type) {
        case "linkedin":
          this.importFile();
          return;

        case "vcard":
          this.importFile();
          return;

        case "exchange":
          this.importFile();
          return;

        case "pdfs":
          this.importFile();
          return;

        default:
          return;
      }
    },
    importFile() {
      this.$refs.file.click();
    },
    notifyError(msg = "If this issue persists, please contact support.") {
      this.$notify({
        group: "notifs",
        title: "Request Failed",
        text: msg,
        type: "error",
      });
    },
    notifySuccess() {
      this.$notify({
        group: "notifs",
        title: "Success",
        text: "Contact import started.",
        type: "success",
      });
    },
  },
};
</script>
