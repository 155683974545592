import axios from "axios";
import Utils from "@/api/utils";

export default {
  fetchMotd() {
    let url = Utils.accountApiUrl("/v1/motd");
    return axios
      .get(url, { headers: Utils.authHeaders() })
      .then((resp) => resp.data);
  },

  updateMotd(msg, color) {
    let url = Utils.accountApiUrl("/v1/motd");
    let params = { text: msg, color: color };

    return axios
      .put(url, params, { headers: Utils.authHeaders() })
      .then((resp) => resp.data);
  },

  deleteMotd() {
    let url = Utils.accountApiUrl("/v1/motd");
    return axios
      .delete(url, { headers: Utils.authHeaders() })
      .then((resp) => resp.data);
  },
};
