<template>
  <v-navigation-drawer
    v-model="tempDrawer"
    :clipped="clipped"
    class="rubber darken-1"
    enable-resize-watcher
    app
    dark
  >
    <v-list>
      <v-list dense class="pt-0">
        <v-list-tile
          v-for="item in sidebarItems"
          :key="item.title"
          :to="item.path"
          active-class="darkNavy"
        >
          <v-list-tile-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title class="subheading">
              {{ item.title }}
            </v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
      <v-list v-if="isSuperAdmin" dense class="pt-0">
        <v-list-tile
          v-for="item in adminSidebarItems"
          :key="item.title"
          :to="item.path"
          active-class="darkNavy"
        >
          <v-list-tile-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title class="subheading">
              {{ item.title }}
            </v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    drawer: Boolean,
    clipped: Boolean,
  },
  data() {
    return {
      tempDrawer: this.drawer,
      sidebarItems: [
        { title: "Home", icon: "dashboard", path: "/dashboard" },
        { title: "Teams", icon: "domain", path: "/accounts" },
        { title: "Users", icon: "people", path: "/users" },
      ],
      adminSidebarItems: [
        { title: "Agents", icon: "assignment_ind", path: "/agents" },
        { title: "Admins", icon: "perm_identity", path: "/admins" },
        { title: "Super Admins", icon: "vpn_key", path: "/super-admins" },
        { title: "MOTD", icon: "chat", path: "/motd" },
      ],
    };
  },
  computed: {
    ...mapGetters(["isSuperAdmin"]),
  },
  watch: {
    drawer(newVal) {
      this.tempDrawer = newVal;
    },
  },
};
</script>
