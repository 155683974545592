<template>
  <v-container fluid>
    <v-layout align-center justify-center fill-height row>
      <v-flex shrink>
        <v-card class="text-xs-center" width="400">
          <v-card-title class="card-title primary">
            <span class="white--text">Forbidden</span>
            <v-spacer />
            <v-icon dark> lock </v-icon>
          </v-card-title>
          <v-card-text>
            <p class="text-xs-center">
              You are not authorized to access this page.
            </p>
            <v-btn color="primary" to="/accounts" class="elevation-0">
              Go Home
            </v-btn>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: "ForbiddenView",
};
</script>
