<template>
  <v-data-table
    :headers="headers"
    :items="users"
    :loading="fetchingData"
    :search="search"
    :rows-per-page-items="[10, 20]"
    no-data-text="No users found."
  >
    <template slot="items" slot-scope="props">
      <router-link :to="{ path: '/users/' + props.item.id }" tag="td">
        {{ props.item.id }}
      </router-link>
      <router-link :to="{ path: '/users/' + props.item.id }" tag="td">
        {{ props.item.email }}
      </router-link>
      <router-link :to="{ path: '/users/' + props.item.id }" tag="td">
        {{ props.item.name }}
      </router-link>
      <router-link :to="{ path: '/users/' + props.item.id }" tag="td">
        {{ props.item.role }}
      </router-link>
      <td class="justify-center layout px-0">
        <v-btn
          color="primary"
          small
          dark
          flat
          @click="requestRemove(props.item)"
        >
          Remove
        </v-btn>
      </td>
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: "MembersTable",
  props: {
    fetchingData: {
      type: Boolean,
      default: false,
    },
    users: {
      type: Array,
      required: true,
    },
    search: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      headers: [
        { text: "ID", align: "left", value: "id" },
        { text: "E-mail", align: "left", value: "email" },
        { text: "Name", align: "left", value: "name" },
        { text: "Role", align: "left", value: "role" },
        { text: "", align: "left", value: "" },
      ],
    };
  },
  methods: {
    requestRemove(user) {
      this.$emit("remove", user);
    },
  },
};
</script>

<style lang="scss">
.status {
  text-transform: capitalize;
}

.inherited {
  color: gray;
  font-style: italic;
}
</style>
