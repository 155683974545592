<template>
  <v-dialog
    v-model="open"
    width="390"
    style="overflow: auto; -webkit-overflow-scrolling: touch"
    @keydown.esc="close"
  >
    <v-card class="pa-2">
      <v-card-title class="card-title"> New User </v-card-title>
      <v-card-text>
        <v-text-field
          v-model="tempUser.name"
          :error-messages="errors['name']"
          label="Name*"
          prepend-icon="person"
          required
        />
        <v-text-field
          v-model="tempUser.email"
          :error-messages="errors['email']"
          label="E-mail*"
          prepend-icon="mail"
          required
        />
        <v-text-field
          v-model="tempUser.password"
          :error-messages="errors['password']"
          label="Password*"
          prepend-icon="lock"
          required
        />
        <v-text-field
          v-model="tempUser.phone_number"
          :error-messages="errors['phone_number']"
          label="Phone No."
          prepend-icon="phone"
        />
        <v-text-field
          v-model="tempUser.address"
          :error-messages="errors['address']"
          label="Address"
          prepend-icon="place"
        />
        <v-layout row wrap>
          <v-flex xs6>
            <v-switch
              v-model="tempUser.verified"
              label="Verified"
              color="primary"
            />
          </v-flex>
          <v-flex xs6>
            <v-switch
              v-model="tempUser.active"
              label="Active"
              color="primary"
            />
          </v-flex>
        </v-layout>
        <v-label>* is required</v-label>
      </v-card-text>
      <v-card-actions>
        <v-btn
          ref="saveButton"
          color="primary"
          class="elevation-0"
          dark
          @click.native="save"
        >
          Create
        </v-btn>
        <v-btn ref="cancelButton" color="primary" flat @click.native="close">
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-progress-linear v-if="isLoading" class="ma-0" indeterminate />
  </v-dialog>
</template>

<script>
import UserApi from "@/api/users";

export default {
  name: "NewUserDialog",
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    role: {
      type: String,
      default: "customer",
    },
    user: {
      type: Object,
      default: () => {
        return {
          role: "customer",
          verified: true,
          active: true,
        };
      },
    },
  },
  data() {
    return {
      isLoading: false,
      open: this.value,
      tempUser: this.user,
      errors: [],
      roles: ["super_admin", "admin", "agent", "customer"],
    };
  },
  watch: {
    value: {
      handler() {
        this.open = this.value;
      },
    },
    user: {
      handler() {
        this.tempUser = { ...this.user };
      },
    },
    open: {
      handler() {
        if (!this.open) {
          this.close();
        }
      },
    },
  },
  methods: {
    save() {
      this.requestCreateUser(this.tempUser);
    },
    reset() {
      this.tempUser = Object.assign({}, this.user);
    },
    requestCreateUser(user) {
      this.isLoading = true;
      user.role = this.role;

      UserApi.createUser(user)
        .then((resp) => {
          this.errors = [];
          this.$emit("save", resp.data);
          this.$emit("close");
          this.$emit("update-users-table");
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        })
        .finally(() => (this.isLoading = false));
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>
