import Vue from "vue";
import * as types from "./mutation-types";
import API from "@/api";
import Utils from "@/api/utils";

export default {
  login({ commit }, { email, password }) {
    return new Promise((resolve, reject) => {
      commit(types.LOGIN_REQUEST);
      API.login(email, password)
        .then((resp) => {
          const token = resp.token;
          commit(types.LOGIN_SUCCESS, { email: email, token: token });
          resolve(resp);
        })
        .catch((error) => {
          var msg = "";

          if (error.response === undefined) {
            msg = "Something went wrong.";
            commit(types.LOGIN_FAILURE, { msg });
            reject(msg);
            return;
          }

          switch (error.response.status) {
            case 401:
            case 404:
              msg = "Invalid email/password.";
              break;

            case 403:
              msg = "Account disabled.";
              break;

            default:
              msg = "Something went wrong.";
              break;
          }

          commit(types.LOGIN_FAILURE, { msg });
          reject(msg);
        });
    });
  },

  oauth({ commit }, { provider, code, type, redirectUri }) {
    return new Promise((resolve, reject) => {
      commit(types.LOGIN_REQUEST);

      API.auth({
        provider: provider,
        code: code,
        type: type,
        redirectUri: redirectUri,
      })
        .then((resp) => {
          const { token, email } = resp;
          commit(types.LOGIN_SUCCESS, { email: email, token: token });
          resolve(resp);
        })
        .catch((error) => {
          var msg = "";

          if (error.response === undefined) {
            msg = "Something went wrong.";
            commit(types.LOGIN_FAILURE, { msg });
            reject(msg);
            return;
          }

          switch (error.response.status) {
            case 401:
            case 404:
              msg = "Account does not exist.";
              break;

            case 403:
              msg = "Account disabled.";
              break;

            default:
              msg = "Something went wrong.";
              break;
          }

          commit(types.LOGIN_FAILURE, { msg });
          reject(msg);
        });
    });
  },

  logout({ commit }) {
    return new Promise((resolve, _reject) => {
      Vue.cookie.delete("admin-token", { domain: Utils.cookieDomain() });
      commit(types.LOGOUT);
      resolve();
    });
  },

  fetchProfile({ commit }) {
    commit(types.FETCH_PROFILE_REQUEST);

    API.fetchProfile()
      .then((resp) =>
        commit(types.FETCH_PROFILE_SUCCESS, { profile: resp.data }),
      )
      .catch((error) => commit(types.FETCH_PROFILE_FAILURE, { msg: error }));
  },

  updatePassword({ commit }, { code, password }) {
    return new Promise((resolve, reject) => {
      commit(types.UPDATE_PASSWORD_REQUEST);

      API.updatePassword(code, password)
        .then((resp) => {
          commit(types.UPDATE_PASSWORD_SUCCESS);
          resolve(resp);
        })
        .catch((error) => {
          commit(types.UPDATE_PASSWORD_FAILURE, { msg: error });
          reject(error);
        });
    });
  },
};
