export const roles = [
  { name: "Customer", role: "customer", rank: 1 },
  { name: "Agent", role: "agent", rank: 2 },
  { name: "Admin", role: "admin", rank: 3 },
  { name: "Super Admin", role: "super_admin", rank: 999 },
];

export default {
  name(roleKey) {
    if (roleKey == null) {
      return null;
    }
    return roles.find((x) => x.role === roleKey).name;
  },
  subroles(roleKey) {
    if (roleKey == null) {
      return [];
    }
    let role = roles.find((x) => x.role === roleKey);
    return role.rank === 999 ? roles : roles.filter((x) => x.rank < role.rank);
  },
};
