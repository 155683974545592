import axios from "axios";
import Utils from "@/api/utils";

export default {
  fetchUsers(params) {
    let url = Utils.accountApiUrl(`/v1/users`);
    return axios
      .get(url, { params: params, headers: Utils.authHeaders() })
      .then((resp) => resp.data);
  },

  fetchUser(userId) {
    let url = Utils.accountApiUrl(`/v1/users/${userId}`);
    return axios
      .get(url, { headers: Utils.authHeaders() })
      .then((resp) => resp.data);
  },

  fetchAccounts(userId) {
    let url = Utils.accountApiUrl(`/v1/users/${userId}/accounts`);
    return axios
      .get(url, { headers: Utils.authHeaders() })
      .then((resp) => resp.data);
  },

  fetchLogins(userId) {
    let url = Utils.accountApiUrl(`/v1/admin/logins/${userId}`);
    return axios
      .get(url, { headers: Utils.authHeaders() })
      .then((resp) => resp.data);
  },

  createUser(user) {
    let url = Utils.accountApiUrl("/v1/users");
    return axios
      .post(url, user, { headers: Utils.authHeaders() })
      .then((resp) => resp.data);
  },

  updateUser(user) {
    let url = Utils.accountApiUrl(`/v1/users/${user.id}`);
    return axios
      .put(url, user, { headers: Utils.authHeaders() })
      .then((resp) => resp.data);
  },

  updatePassword(userId, password) {
    let url = Utils.accountApiUrl(`/v1/users/${userId}/password`);
    let data = { password: password };

    return axios
      .put(url, data, { headers: Utils.authHeaders() })
      .then((resp) => resp.data);
  },

  deleteUser(userId) {
    let url = Utils.accountApiUrl(`/v1/users/${userId}`);
    return axios
      .delete(url, { headers: Utils.authHeaders() })
      .then((resp) => resp.data);
  },

  searchUsers(q, role) {
    let roleQuery = role == null ? "" : `&role=${role}`;
    let url = Utils.accountApiUrl(`/v1/users?q=${q}${roleQuery}&admin=true`);
    return axios
      .get(url, { headers: Utils.authHeaders() })
      .then((resp) => resp.data);
  },

  fetchContactTotals(userId) {
    let url = Utils.contactApiUrl(`/v1/users/${userId}/statistics`);
    return axios
      .get(url, { headers: Utils.authHeaders() })
      .then((resp) => resp.data);
  },
};
