import axios from "axios";

import Utils from "@/api/utils";

export default {
  getExemptStatus(userHashId) {
    let url = Utils.billingApiUrl(`/v1/billing/users/${userHashId}`);
    return axios
      .get(url, { headers: Utils.authHeaders() })
      .then((resp) => resp.data);
  },

  updateExemptStatus(userHashId, exemptStatus) {
    let url = Utils.billingApiUrl(`/v1/billing/users/${userHashId}`);
    return axios
      .put(
        url,
        { billing_exempt: exemptStatus },
        { headers: Utils.authHeaders() },
      )
      .then((resp) => resp.data);
  },
};
