import Vue from "vue";
import Roles from "@/roles";

export default {
  isAuthenticated: (_state) => !!Vue.cookie.get("admin-token"),

  isSuperAdmin: (state) => {
    if (state.profile === null) {
      return null;
    }
    return state.profile.role === "super_admin";
  },

  getUsers: (state) => state.userCodes.map((code) => state.users[code]),

  getUserByCode: (state) => (code) => {
    return state.users[code];
  },

  getProfile: (state) => state.profile,

  getRole: (state) => {
    if (state.profile === null) {
      return null;
    }
    return state.profile.role;
  },

  getSubRoles: (state) => {
    if (state.profile == null) {
      return [];
    }
    return Roles.subroles(state.profile.role);
  },
};
