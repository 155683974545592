import Vue from "vue";
import whitelabelConfig from "@/whitelabel.config";

export default {
  authHeaders() {
    return { Authorization: `Bearer ${Vue.cookie.get("admin-token")}` };
  },
  cookieDomain() {
    return this.getWhiteLabelConfig().domain;
  },
  loginUrl() {
    return this.getWhiteLabelConfig().loginUrl;
  },
  accountApiUrl(path) {
    return this.getWhiteLabelConfig().accountApiUrl + path;
  },
  apiUrl(path) {
    return this.getWhiteLabelConfig().apiUrl + path;
  },
  contactApiUrl(path) {
    return this.getWhiteLabelConfig().contactApiUrl + path;
  },
  contactImportApiUrl(path) {
    return this.getWhiteLabelConfig().contactImportApiUrl + path;
  },
  billingApiUrl(path) {
    return this.getWhiteLabelConfig().billingApiUrl + path;
  },
  warehouseApiUrl(path) {
    return this.getWhiteLabelConfig().warehouseApiUrl + path;
  },
  getWhiteLabelConfig() {
    return whitelabelConfig[process.env.VUE_APP_BRAND];
  },
};
