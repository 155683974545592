<template>
  <v-container grid-list-lg fluid>
    <h1>Message of the Day</h1>
    <v-layout class="mb-3" row wrap>
      <v-flex>
        <v-card
          v-if="hasText"
          :color="color"
          class="elevation-0"
          style="border-radius: 0"
          absolute
          dark
        >
          <v-card-title>
            <v-spacer />
            <strong>{{ text }}</strong>
            <v-spacer />
          </v-card-title>
        </v-card>
        <v-card v-else class="transparent elevation-0">
          <v-card-title>
            <v-spacer />
            No message set.
            <v-spacer />
          </v-card-title>
        </v-card>
      </v-flex>
    </v-layout>
    <v-layout row wrap>
      <v-flex>
        <v-card>
          <v-card-title class="card-title"> Edit Message </v-card-title>
          <v-divider />
          <v-card-text>
            <v-text-field v-model="tempText" placeholder="Write something..." />
            <ColorPicker :color="tempColor" @select="changeColor($event)" />
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="primary"
              class="elevation-0 ma-1"
              large
              dark
              @click="updateMOTD"
            >
              Update
            </v-btn>
            <v-btn
              color="rubber"
              class="elevation-0"
              large
              dark
              @click="deleteMOTD"
            >
              <v-icon left> delete </v-icon>
              Delete
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import Api from "@/api/motd";

import ColorPicker from "@/components/ColorPicker";

export default {
  name: "MOTDView",
  components: { ColorPicker },
  data() {
    return {
      text: "",
      color: "",
      tempText: "",
      tempColor: "",
    };
  },
  computed: {
    hasText() {
      return this.text !== "" && this.text !== null;
    },
  },
  mounted() {
    this.fetchMOTD();
  },
  methods: {
    changeColor(color) {
      this.tempColor = color;
    },
    fetchMOTD() {
      Api.fetchMotd().then((resp) => {
        this.text = resp.data.text;
        this.tempText = resp.data.text;
        this.color = resp.data.color;
        this.tempColor = resp.data.color;
      });
    },
    updateMOTD() {
      Api.updateMotd(this.tempText, this.tempColor).then((_resp) => {
        this.text = this.tempText;
        this.color = this.tempColor;
      });
    },
    deleteMOTD() {
      Api.deleteMotd().then((_resp) => {
        this.text = "";
        this.tempText = "";
        this.color = "";
        this.tempColor = "";
      });
    },
  },
};
</script>
