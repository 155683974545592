<template>
  <v-container grid-list-lg fluid>
    <v-breadcrumbs :items="breadcrumbs" divider="/"></v-breadcrumbs>
    <v-layout row wrap>
      <v-flex xs12 lg4>
        <UserCard :user="user" @toggleEdit="showEditUserDialog" />
        <PaymentCard :id="id" />
        <h1 class="mt-3">Recent Logins</h1>
        <v-data-table
          :headers="loginHeaders"
          :items="logins"
          hide-actions
          hide-headers
          :pagination.sync="loginSort"
          class="mt-1 elevation-2"
        >
          <template v-slot:items="props">
            <td>{{ datetimeString(props.item.time) }}</td>
          </template>
        </v-data-table>
        <v-card-title class="card-title">
          <v-icon class="mr-3"> person </v-icon>
          {{ totalLogins }} total
        </v-card-title>
        <v-btn
          v-if="isSuperAdmin"
          color="rubber"
          class="mt-3"
          large
          block
          dark
          @click="showDeleteDialog"
        >
          Delete User
        </v-btn>
      </v-flex>
      <v-flex xs12 lg8>
        <v-layout row>
          <v-flex xs12>
            <h1>
              Teams
              <v-btn color="primary" small flat @click="showAddAccountDialog">
                <v-icon left> add </v-icon>
                Add
              </v-btn>
            </h1>
            <v-data-table
              :headers="accountHeaders"
              :items="accounts"
              class="elevation-2"
            >
              <v-progress-linear
                slot="progress"
                color="primary"
                indeterminate
              ></v-progress-linear>
              <template slot="items" slot-scope="props">
                <router-link
                  :to="{ path: '/accounts/' + props.item.id }"
                  tag="tr"
                >
                  <td>{{ props.item.id }}</td>
                  <td>{{ props.item.name }}</td>
                  <td>{{ props.item.role }}</td>
                  <td>
                    <v-btn
                      color="primary"
                      small
                      flat
                      @click="removeRole(props.item.id)"
                    >
                      Remove
                    </v-btn>
                  </td>
                </router-link>
              </template>
            </v-data-table>
          </v-flex>
        </v-layout>
        <ContactTotals :id="id" />
        <ImportContacts :id="id" :name="user.name" />
        <v-layout row>
          <v-flex xs12>
            <h1 class="mb-1">Update Password</h1>
            <ChangePassword
              ref="changePassword"
              @requestUpdatePassword="requestUpdatePassword($event.password)"
            />
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <EditUserDialog
      v-model="editDialog"
      :user="user"
      @update="updateUser($event)"
      @close="closeEditUserDialog"
    />
    <AddAccountToUserDialog
      v-model="addAccountDialog"
      :user="user"
      @add="addAccount($event)"
      @close="addAccountDialog = false"
    />
    <v-dialog v-model="deleteDialog" width="290">
      <DeleteForm
        title="Confirm Delete"
        @requestDelete="requestDelete"
        @cancel="closeDeleteDialog"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import AccountApi from "@/api/accounts";
import AddAccountToUserDialog from "../components/users/AddAccountToUserDialog";
import ChangePassword from "../components/users/ChangePassword";
import ContactTotals from "../components/users/ContactTotals";
import DeleteForm from "../components/DeleteForm";
import EditUserDialog from "../components/users/EditUserDialog";
import ImportContacts from "../components/users/ImportContacts";
import PaymentCard from "../components/users/PaymentCard";
import UserApi from "@/api/users";
import UserCard from "../components/users/UserCard";

const { DateTime } = require("luxon");

export default {
  name: "UserDetailView",
  components: {
    AddAccountToUserDialog,
    ChangePassword,
    ContactTotals,
    DeleteForm,
    EditUserDialog,
    ImportContacts,
    PaymentCard,
    UserCard,
  },
  props: {
    id: {
      type: String,
      required: true,
      default: "0",
    },
  },
  data() {
    return {
      orgSearch: "",
      editDialog: false,
      deleteDialog: false,
      addAccountDialog: false,
      isLoading: false,
      user: {},
      accounts: [],
      logins: [],
      loginSort: {
        sortBy: "time",
        descending: true,
      },
      totalLogins: 0,
      accountHeaders: [
        { text: "ID", align: "left", value: "code" },
        { text: "Name", align: "left", value: "name" },
        { text: "Role", align: "left", value: "role" },
        { text: "", align: "center", value: "", width: "10%" },
      ],
      loginHeaders: [{ text: "time", align: "left", value: "time" }],
      breadcrumbs: [
        {
          text: "Users",
          disabled: false,
          href: "/users",
        },
        {
          text: this.id,
          disabled: false,
          href: "/users/" + this.id,
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["isSuperAdmin"]),
  },
  created() {
    this.requestFetchUser();
    this.requestFetchAccounts();
    this.requestFetchLogins();
  },
  methods: {
    addAccount(account) {
      this.accounts = this.accounts.filter((x) => x.id !== account.id);
      this.accounts.push(account);
    },
    close() {
      this.editDialog = false;
      this.deleteDialog = false;
    },
    closeEditUserDialog() {
      this.editDialog = false;
    },
    closeDeleteDialog() {
      this.deleteDialog = false;
    },
    datetimeString(datetime) {
      if (datetime == null) {
        return "Not set.";
      }
      return DateTime.fromISO(datetime, { zone: "utc" })
        .toLocal()
        .toLocaleString(DateTime.DATETIME_MED);
    },
    requestFetchUser() {
      this.isLoading = true;

      UserApi.fetchUser(this.id)
        .then((resp) => {
          this.user = Object.assign({}, resp.data);
        })
        .finally(() => (this.isLoading = false));
    },
    requestFetchAccounts() {
      this.isLoading = true;

      UserApi.fetchAccounts(this.id)
        .then((resp) => {
          this.accounts = resp.data;
        })
        .finally(() => (this.isLoading = false));
    },
    requestFetchLogins() {
      UserApi.fetchLogins(this.id).then((resp) => {
        this.logins = resp.data.logins;
        this.totalLogins = resp.data.total;
      });
    },
    requestDelete() {
      UserApi.deleteUser(this.id).then((_resp) => {
        this.$notify({
          group: "notifs",
          title: "Success",
          text: "User Deleted",
          type: "success",
        });
        this.$router.go(-1);
      });
      this.closeDeleteDialog();
    },
    requestUpdatePassword(password) {
      UserApi.updatePassword(this.id, password).then((_resp) => {
        this.$notify({
          group: "notifs",
          title: "Password Updated",
          type: "success",
        });
        this.$refs.changePassword.clear();
      });
    },
    removeRole(accountId) {
      AccountApi.removeUserRole(accountId, this.id).then((_resp) => {
        this.accounts = this.accounts.filter((x) => x.id !== accountId);
      });
    },
    showAddAccountDialog() {
      this.addAccountDialog = true;
    },
    showDeleteDialog() {
      this.deleteDialog = true;
    },
    showEditUserDialog() {
      this.editDialog = true;
    },
    updateUser(user) {
      this.$notify({
        group: "notifs",
        title: "Success",
        text: "User Updated",
        type: "success",
      });
      this.user = { ...user };
      this.closeEditUserDialog();
    },
  },
};
</script>
